.container {
  .body {
    background-image: url(../../images/bg-decor.gif);

    main {
      padding: 5rem 1rem;
      display: flex;
      justify-content: center;

      img {
        max-width: 700px;
        object-fit: cover;
        object-position: center;
      }
      .text_card {
        color: rgb(45, 42, 42);
        font-size: 15px;
      }
      .btn_1 {
        border: none;
        border-radius: 10px;
        margin: 1rem 0;
        cursor: pointer;
        color: white;
        background-color: rgba(4, 162, 4, 0.933);
      }
      .paymentContainer {
        h3 {
          background: white;
          padding: 8px 10px;
          border-radius: 10px;
          text-align: center;
          margin: 10px 0;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        form {
          display: grid;
          grid-template-columns: auto auto;
          margin: 1rem 0;
          .third_input {
            margin: 10px 0;
            grid-column: 1/3;
          }
        }
      }
    }
    @media only screen and (max-width: 1000px) {
      main {
        flex-direction: column;
        width: 90%;
        margin: auto;
        padding: 0;
      }
    }
  }
}
