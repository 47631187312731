.body {
  height: 50vh;
  position: relative;

  .carousel {
    img {
      height: 50vh;
      object-fit: cover;
      object-position: center;
    }
  }

  .headerOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
    top: 0;

    nav {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem 2rem;
      color: white;

      img {
        width: 200px;
      }

      .contactInfo {
        color: white;
        font-size: 16px;
        @media (max-width: 700px) {
          font-size: 12px;
        }
        p {
          margin: 5px 0;
          display: flex;
          align-items: center;
          gap: 5px;
        }

        .socialLinks {
          display: flex;
          gap: 1.5rem;
          a {
            text-decoration: none;
            color: white;
            :hover {
              color: rgb(79, 101, 197);
            }
          }
        }
      }

      @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
      }
    }
  }
}
