.body {
  .cityCarousel {
    height: 70vh;
    text-align: center;
    img {
      width: 100vw;
      height: 100%;
      object-fit: cover;
      object-position: 100% 30%;
    }

    .info {
      position: absolute;
      width: 60%;
      padding: 3rem 1rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      border-radius: 5px;
      display: grid;
      place-items: center;
      gap: 2rem;

      h1 {
        font-size: 3rem;
        letter-spacing: 4px;
        @media screen and (max-width: 1000px) {
          font-size: 30px;
        }
      }

      p {
        max-width: 700px;
        line-height: 28px;
        font-size: 17px;
      }

      button {
        padding: 15px 30px;
        background-color: #023047;
        color: white;
        font-size: 18px;
        letter-spacing: 1px;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        cursor: pointer;
        transition: 0.2s ease;
      }
    }
    @media screen and (max-width: 700px) {
      .cityCarousel {
        width: 100%;
      }
    }
  }
}
