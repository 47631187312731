a {
  text-decoration: none;
}
.body {
  background-image: url(../../images/bg-decor.gif);
}
.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
  gap: 2rem;
  .image_slider {
    margin: 20px 0;
    border-radius: 20px;
    padding: 20px;
    overflow: hidden;
    height: 300px;
    width: 48%;
    background: rgb(250, 248, 248);
    h1 {
      text-decoration: none !important;
      color: black;
    }
    .image_tracker {
      margin-top: 15px;
      display: flex;
      width: calc(150px * 12);
      &:hover {
        animation: play 20s linear infinite;
      }

      .slide {
        height: 100%;
        width: 200px;
        display: grid;
        place-items: center;
        padding: 0 10px;
      }
    }
  }
  img {
    height: 200px;
    width: 100%;
    transition: transform 1s;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  @keyframes play {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-200px * 5));
    }
  }
}
@media screen and (max-width: 1200px) {
  .wrapper {
    flex-direction: column;
    width: 100%;
    margin: auto;
    .image_slider {
      width: 100%;
    }
  }
}
