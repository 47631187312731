.body {
  background-image: url(../../images/bg-decor.gif);

  .main {
    max-width: 1200px;
    padding: 2rem 1rem;
    margin: auto;
  }

  video {
    width: 100%;
  }
}
